import styled from 'styled-components';
export const NewCollectionWrapper = styled.div.withConfig({
  displayName: "NewCollectionLayoutstyle__NewCollectionWrapper",
  componentId: "sc-10ii5l0-0"
})(["", " @media (max-width:", "){", "}"], {
  "marginBottom": "6rem"
}, ({
  theme
}) => theme.breakpoint.md, {
  "marginBottom": "4rem"
});
export const Container = styled.div.withConfig({
  displayName: "NewCollectionLayoutstyle__Container",
  componentId: "sc-10ii5l0-1"
})(["", ""], {
  "display": "grid",
  "gridTemplateColumns": "repeat(2, minmax(0, 1fr))",
  "gap": "2rem"
});
export const LeftContent = styled.div.withConfig({
  displayName: "NewCollectionLayoutstyle__LeftContent",
  componentId: "sc-10ii5l0-2"
})([""]);
export const RightContent = styled.div.withConfig({
  displayName: "NewCollectionLayoutstyle__RightContent",
  componentId: "sc-10ii5l0-3"
})(["", ""], {
  "display": "grid",
  "gridTemplateColumns": "repeat(2, minmax(0, 1fr))",
  "gap": "2rem"
});
export const MobileRecipeThumbWrapper = styled.div.withConfig({
  displayName: "NewCollectionLayoutstyle__MobileRecipeThumbWrapper",
  componentId: "sc-10ii5l0-4"
})(["", " @media (max-width:", "){", "}"], {
  "marginLeft": "-1rem",
  "marginRight": "-1rem",
  "marginTop": "1rem",
  "display": "flex",
  "gap": "1rem",
  "overflowX": "scroll",
  "paddingLeft": "1rem",
  "paddingRight": "1rem",
  "paddingBottom": "1.25rem"
}, ({
  theme
}) => theme.breakpoint.md, {
  "marginTop": "0.5rem",
  "gap": "0.5rem"
});