import Link from 'next/link';
import styled from 'styled-components';
export const Container = styled.div.withConfig({
  displayName: "Coverstyle__Container",
  componentId: "sc-1jen7g0-0"
})(["", " background-image:url(", "),url('/images/default/collection-l.png');.background-vdo{", "}.overlay-cover{", " background:rgba(0,0,0,0.30);z-index:1;}@media (max-width:", "){", "}"], {
  "position": "relative",
  "height": "100%",
  "minHeight": "356px",
  "width": "100%",
  "overflow": "hidden",
  "borderRadius": "1rem",
  "backgroundSize": "cover",
  "backgroundPosition": "center",
  "backgroundRepeat": "no-repeat"
}, ({
  $imageUrl
}) => `${$imageUrl}`, {
  "position": "absolute",
  "height": "100%",
  "width": "100%",
  "objectFit": "cover"
}, {
  "position": "absolute",
  "height": "100%",
  "width": "100%"
}, ({
  theme
}) => theme.breakpoint.md, {
  "height": "100%"
});
export const ContentWrapper = styled.div.withConfig({
  displayName: "Coverstyle__ContentWrapper",
  componentId: "sc-1jen7g0-1"
})(["", " .title{position:relative;z-index:8;}@media (max-width:", "){", " aspect-ratio:8/7;}@media (max-width:", "){", " aspect-ratio:7/8;}"], {
  "position": "relative",
  "display": "flex",
  "height": "100%",
  "width": "100%",
  "flexDirection": "column",
  "justifyContent": "space-between",
  "padding": "2.5rem"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "padding": "1.75rem"
}, ({
  theme
}) => theme.breakpoint.md, {
  "padding": "1rem"
});
export const CollectionLink = styled(Link).withConfig({
  displayName: "Coverstyle__CollectionLink",
  componentId: "sc-1jen7g0-2"
})(["", " z-index:3;"], {
  "position": "absolute",
  "left": "0px",
  "top": "0px",
  "height": "100%",
  "width": "100%"
});
export const MenuAmount = styled.div.withConfig({
  displayName: "Coverstyle__MenuAmount",
  componentId: "sc-1jen7g0-3"
})(["", " font-size:", ";@media (max-width:", "){", "}"], {
  "fontWeight": "400",
  "--tw-text-opacity": "1",
  "color": "rgb(255 255 255 / var(--tw-text-opacity))"
}, ({
  $menuAmountSize
}) => $menuAmountSize ? `${$menuAmountSize}px` : '30px', ({
  theme
}) => theme.breakpoint.md, {
  "marginBottom": "0.5rem",
  "fontSize": "1.5rem",
  "lineHeight": "2rem"
});
export const Title = styled.div.withConfig({
  displayName: "Coverstyle__Title",
  componentId: "sc-1jen7g0-4"
})(["", " z-index:2;"], {
  "position": "relative"
});
export const Name = styled.div.withConfig({
  displayName: "Coverstyle__Name",
  componentId: "sc-1jen7g0-5"
})(["", " font-size:", ";line-height:", ";@media (max-width:", "){", "}"], {
  "whiteSpace": "pre-wrap",
  "fontWeight": "700",
  "--tw-text-opacity": "1",
  "color": "rgb(255 255 255 / var(--tw-text-opacity))"
}, ({
  $nameSize
}) => $nameSize ? `${$nameSize}px` : '72px', ({
  $nameLineHeight
}) => $nameLineHeight ? `${$nameLineHeight}px` : '64px', ({
  theme
}) => theme.breakpoint.md, {
  "fontSize": "45px",
  "lineHeight": "50px"
});
export const RecipeList = styled.div.withConfig({
  displayName: "Coverstyle__RecipeList",
  componentId: "sc-1jen7g0-6"
})(["", " @media (max-width:", "){grid-template-columns:80px 80px 80px 80px;}@media (max-width:", "){grid-template-columns:64px 64px 64px 64px;", "}"], {
  "display": "grid",
  "width": "100%",
  "gridTemplateColumns": "repeat(4, minmax(0, 1fr))",
  "gap": "1rem"
}, ({
  theme
}) => theme.breakpoint.lg, ({
  theme
}) => theme.breakpoint.md, {
  "gap": "0.5rem"
});
export const RecipeItem = styled.div.withConfig({
  displayName: "Coverstyle__RecipeItem",
  componentId: "sc-1jen7g0-7"
})(["", " .recipe-image{", "}img{", " transition:all 0.25s ease-in-out;z-index:4;&:hover{transform:scale(1.15);transition:all 0.3s ease-in;}}@media (max-width:", "){img{&:hover{transform:scale(1);transition:none;}}}"], {
  "aspectRatio": "1 / 1",
  "width": "100%",
  "cursor": "pointer",
  "overflow": "hidden",
  "borderRadius": "0.75rem"
}, {
  "height": "100%",
  "width": "100%"
}, {
  "position": "relative",
  "height": "100% !important",
  "width": "100% !important",
  "objectFit": "cover"
}, ({
  theme
}) => theme.breakpoint.md);