import { Image } from 'antd';
import styled from 'styled-components';
export const Container = styled.div.withConfig({
  displayName: "RecipeThumbstyle__Container",
  componentId: "sc-1nbxxi6-0"
})(["", " width:", ";"], {
  "display": "flex",
  "height": "100%",
  "flexDirection": "column",
  "overflow": "hidden"
}, ({
  $width
}) => `${$width ?? 'auto'}`);
export const ImgContainer = styled.div.withConfig({
  displayName: "RecipeThumbstyle__ImgContainer",
  componentId: "sc-1nbxxi6-1"
})(["", " .wrapper{", " .ant-image{", "}}.related-product{", " @media (max-width:", "){", "}@media (max-width:", "){", "}}"], {
  "position": "relative"
}, {
  "aspectRatio": "1 / 1",
  "height": "auto",
  "width": "100%",
  "overflow": "hidden",
  "borderRadius": "1rem"
}, {
  "width": "100%"
}, {
  "position": "absolute",
  "bottom": "-50px",
  "right": "0.25rem",
  "height": "10rem",
  "width": "10rem",
  "objectFit": "contain",
  "objectPosition": "bottom"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "height": "8rem",
  "width": "8rem"
}, ({
  theme
}) => theme.breakpoint.md, {
  "height": "6rem",
  "width": "6rem"
});
export const RecipeImg = styled(Image).withConfig({
  displayName: "RecipeThumbstyle__RecipeImg",
  componentId: "sc-1nbxxi6-2"
})(["", " transition:all 0.25s ease-in-out;&:hover{transform:scale(1.15);transition:all 0.3s ease-in;}@media (max-width:", "){&:hover{transform:scale(1);transition:none;}}"], {
  "aspectRatio": "1 / 1",
  "height": "100%",
  "width": "100%",
  "objectFit": "cover"
}, ({
  theme
}) => theme.breakpoint.md);
export const Viewer = styled.div.withConfig({
  displayName: "RecipeThumbstyle__Viewer",
  componentId: "sc-1nbxxi6-3"
})(["", " img{", "}span{", " text-shadow:0px 1px 2px rgba(0,0,0,0.60);}"], {
  "position": "absolute",
  "bottom": "0.5rem",
  "left": "0.5rem",
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "flex-start",
  "gap": "0.25rem"
}, {
  "height": "1.25rem",
  "width": "1.25rem"
}, {
  "fontSize": "1.125rem",
  "lineHeight": "1.75rem",
  "fontWeight": "400",
  "--tw-text-opacity": "1",
  "color": "rgb(255 255 255 / var(--tw-text-opacity))"
});
export const RelatedProduct = styled(Image).withConfig({
  displayName: "RecipeThumbstyle__RelatedProduct",
  componentId: "sc-1nbxxi6-4"
})(["", ""], {
  "height": "100% !important",
  "objectFit": "contain",
  "objectPosition": "bottom"
});
export const Name = styled.div.withConfig({
  displayName: "RecipeThumbstyle__Name",
  componentId: "sc-1nbxxi6-5"
})(["", " display:", ";width:", ";height:", ";line-clamp:", ";-webkit-line-clamp:", ";-webkit-box-orient:vertical;color:", ";letter-spacing:", ";@media (max-width:", "){", " display:-webkit-box;height:", ";}"], {
  "marginTop": "0.5rem",
  "overflow": "hidden",
  "textOverflow": "ellipsis",
  "fontSize": "28px",
  "fontWeight": "700",
  "lineHeight": "30px",
  "--tw-text-opacity": "1",
  "color": "rgb(43 43 43 / var(--tw-text-opacity))"
}, ({
  $maxLineName
}) => $maxLineName === 1 ? 'block' : '-webkit-box', ({
  $isHaveRelatedProduct
}) => `${$isHaveRelatedProduct ? '70%' : '100%'}`, ({
  $maxLineName
}) => $maxLineName === 1 ? 'unset' : `${$maxLineName * 29}px`, ({
  $maxLineName
}) => `${$maxLineName ?? 2}`, ({
  $maxLineName
}) => `${$maxLineName ?? 2}`, ({
  $textColor
}) => $textColor, ({
  $defineNameForLetterSpace
}) => $defineNameForLetterSpace === 'ข้าวซอยหมูกรอบชาชู ซีพีคูโรบูตะ' ? '0.3px' : 'auto', ({
  theme
}) => theme.breakpoint.md, {
  "fontSize": "21px",
  "lineHeight": "1.5rem"
}, ({
  $maxLineName
}) => $maxLineName === 1 ? 'unset' : `${$maxLineName * 21}px`);