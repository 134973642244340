import { FC } from 'react';
import Link from 'next/link';
import { Image } from 'antd';
import { isEmpty } from 'radash';
import { Container, ImgContainer, Name, RecipeImg, RelatedProduct } from './RecipeThumb.style';
interface Props {
  imageUrl: string;
  name: string;
  viewer: number;
  relatedProductUrl?: string;
  videoUrl?: string;
  maxLineName?: number;
  slug: string;
  textColor?: string;
  width?: string;
}
const RecipeThumb: FC<Props> = ({
  imageUrl,
  name,
  viewer,
  relatedProductUrl,
  videoUrl,
  maxLineName,
  slug,
  textColor,
  width
}: Props) => {
  return <Link href={`/recipe/${slug}`}>
            <Container $width={width || ''}>
                <ImgContainer>
                    <div className="wrapper">
                        {!isEmpty(imageUrl) && <RecipeImg src={imageUrl || '/images/default/recipe.jpg'} alt={name} onError={({
            currentTarget
          }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = '/images/default/recipe.jpg';
          }} loading="lazy" preview={false} placeholder={<Image src="/images/default/recipe.jpg" alt="defualt image" width={'auto'} preview={false} />} />}
                        {/* TODO : รอใส่ hover VDO */}
                    </div>
                    {/* //TODO: ปิดไว้ก่อน รอเปิดตอน integrate login แล้ว */}
                    {/* {viewer > 0 && (
                        <Viewer>
                            <img src="/icons/white-eye.svg" alt="white-eye" />
                            <span>
                                {numeral(viewer).format('0.0a').toLowerCase()}
                            </span>
                        </Viewer>
                     )} */}

                    {relatedProductUrl && <RelatedProduct wrapperClassName="related-product" src={relatedProductUrl || '/images/default/product.jpg'} alt={name} onError={({
          currentTarget
        }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = '/images/default/product.jpg';
          currentTarget.style.display = 'none';
        }} preview={false} placeholder={<Image src="/images/default/product.jpg" alt="defualt image" width={'auto'} preview={false} />} />}
                </ImgContainer>
                <Name $maxLineName={maxLineName ?? 2} $isHaveRelatedProduct={!!relatedProductUrl} $textColor={textColor} $defineNameForLetterSpace={name}>
                    {name}
                </Name>
            </Container>
        </Link>;
};
export default RecipeThumb;